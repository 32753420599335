
import { Component, Vue, Watch } from "vue-property-decorator";
import i18n from "../../plugins/i18n";

import { Chart, ChartDataset, registerables } from "chart.js";
import CalculationFunctionService from "../../services/sporteventCalculation/CalculationFunctionService";
import DisciplineService from "../../services/sporteventCalculation/DisciplineService";
import Discipline from "../../models/sporteventCalculation/Discipline";
import CalculationFunction from "../../models/sporteventCalculation/CalculationFunction";

@Component({})
export default class DisciplineFunctionsComponent extends Vue {
  // Loading
  public loading = true;

  // Discipline
  public discipline: Discipline = new Discipline();

  // Table
  public items: CalculationFunction[] = [];
  public fields = [
    {
      key: "name",
      sortable: true,
      label: i18n.tc("labels.name"),
    },
    {
      key: "actions",
      sortable: false,
      label: "",
      class: "text-center",
    },
  ];
  public filter = "";
  public perPage = 10;
  public pageOptions = [5, 10, 20, 30];
  public totalRows = 1;
  public currentPage = 1;

  getAll(): void {
    DisciplineService.get(this.$route.params.discipline_id).then(
      (discipline) => {
        this.discipline = new Discipline(discipline);
      }
    );

    CalculationFunctionService.getAllByDisciplineId(
      this.$route.params.discipline_id
    ).then((items: CalculationFunction[] | undefined) => {
      if (items === undefined) return;
      this.items = items;
      if (this.items !== undefined) {
        this.totalRows = this.items.length;
        this.fillData();
      }
      this.loading = false;
    });
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(): void {
    this.getAll();
    this.fillData();
  }

  handleBack(): void {
    this.$router.back();
  }

  getOptions(): any {
    return {
      scales: {
        x:{
            type: "linear",
            position: "bottom",
            title: {
              display: true,
              text: `Leistung in ${this.discipline.unit.name}`
            }
          },
        y: {
            title: {
              display: true,
              text: 'Punkte für Berechnung'
            }
          }
      },
    };
  }

  fillData(): void {
    const datasets: ChartDataset[] = [];
    if (this.items) {
      this.items.sort((a, b) =>
        a.ageGenderGroup.ageFrom < b.ageGenderGroup.ageFrom
          ? -1
          : a.ageGenderGroup.ageFrom > b.ageGenderGroup.ageFrom
          ? 1
          : 0
      );

      this.items.forEach(function (value) {
        const dynamicColors = function () {
          const r = Math.floor(Math.random() * 255);
          const g = Math.floor(Math.random() * 255);
          const b = Math.floor(Math.random() * 255);
          return "rgb(" + r + "," + g + "," + b + ")";
        };

        const calculationFunction = new CalculationFunction(value);
        datasets.push({
          label: calculationFunction.name,
          borderColor: dynamicColors(),
          data: [
            {
              x: calculationFunction.value0,
              y: calculationFunction.point0,
            },
            {
              x: calculationFunction.value1,
              y: calculationFunction.point1,
            },
            {
              x: calculationFunction.value2,
              y: calculationFunction.point2,
            },
            {
              x: calculationFunction.value3,
              y: calculationFunction.point3,
            },
          ],
        });
      });
    }

    Chart.register(...registerables);
    const ctx = document.getElementById("functionschart") as HTMLCanvasElement;

    new Chart(ctx, {
      type: "line",
      data: {
        datasets: datasets,
      },
      options: this.getOptions(),
    });
  }
}
